import { create } from 'apisauce';
import { StatusCodes } from 'http-status-codes';
// import axios from 'axios';
import config from '../../config/config-server'
import authHeader from '@/services/auth-header.js';
import LocalStorage from '@/shared/utils/localStorage.js'

const baseURL = `${config.AUTH_HOST}`;

const api = create({
    baseURL,
    crossDomain: true,
    responseType: 'json',
    headers: {
        'content-type': 'application/json',
    },
})


const parseTokenPayload = (token) => {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
};


const getTokenTTL = (token) => {
    return parseTokenPayload(token).exp;
}


const saveToken = ({ accessToken, refreshToken }) => {
    LocalStorage.set('accessToken', accessToken, getTokenTTL(accessToken));

    if (refreshToken !== undefined) {
        LocalStorage.set('refreshToken', refreshToken, getTokenTTL(refreshToken));
    }
};


const resetAll = () => {
    LocalStorage.remove('user');
    LocalStorage.remove('accessToken');
    LocalStorage.remove('refreshToken');
}


const saveUser = (data, ttl) => {
    const userData = {
        username: data.username,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        role: data.role.name,
    }

    LocalStorage.set('user', userData, ttl);
    return userData
}


class AuthService {
    async login(username, password) {
        var response = await api.post('/token', { username: username, password: password });
        if (response.status !== 200) {
            return null;
        }

        const accessToken = response.data.access_token;
        const refreshToken = response.data.refresh_token;

        saveToken({ accessToken: accessToken, refreshToken: refreshToken });

        response = await api.get('/users/me', {}, { headers: authHeader() });
        if (response.status !== 200) {
            return null;
        }

        const userData = saveUser(response.data, getTokenTTL(accessToken));
        return userData;
    }

    logout() {
        resetAll();
    }

    async refresh() {
        const refreshToken = LocalStorage.get('refreshToken');
        if (refreshToken === null) {
            resetAll();
            return false;
        }
        var response = await api.post('/token/refresh', {}, { headers: { 'Authorization': 'Bearer ' + refreshToken } });

        if (response.status !== StatusCodes.OK) {
            resetAll();
            return false;
        }
        const accessToken = response.data.access_token;
        saveToken({ accessToken: accessToken });

        response = await api.get('/users/me', {}, { headers: authHeader() });
        if (response.status !== 200) {
            return
        }

        saveUser(response.data, getTokenTTL(accessToken));
        return response.ok;
    }
}

export default new AuthService();
