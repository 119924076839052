<template>
  <div>
      <v-row class="d-xl-flex flex-xl-row d-lg-flex flex-lg-row d-md-flex flex-md-row d-sm-flex flex-sm-column d-flex flex-column">
        <v-col cols="3" xs="4" sm="4" md="3" lg="3" xl="2">
          <Summary @withImageCheckboxChanged="resetAndFetchItems()"></Summary>
        </v-col>

        <v-col>
          <v-row class="ma-4">
            <v-col
              v-for="item in items"
              :key="item.id"
              class="d-flex child-flex"
              cols="3" xs="4" sm="4" md="3" lg="3" xl="2"
            >
              <ImageHoverable :item="item"></ImageHoverable>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import ImageHoverable from "@/components/ImageHoverable.vue";
import Summary from "@/components/Summary.vue";
import { getItems } from "@/shared/api";
import LocalStorage from '@/shared/utils/localStorage.js'

export default {
  name: "Home",
  components: {
    ImageHoverable,
    Summary,
  },
  data() {
    return {
      bottom: false,
      fetchLocked: false,
      bottomWatcherOn: true,
      limit: 30,
      offset: 0,
      items: [],
    };
  },
  async created() {
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
    await this.fetchItems();
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY + 1 >= pageHeight;
      return !this.fetchLocked && (bottomOfPage || pageHeight < visible);
    },
    async fetchItems() {
      const withImage = LocalStorage.get('withImage')=== false ? null : true;
      const response = await getItems({
        limit: this.limit,
        offset: this.offset,
        search: this.searchText,
        with_image: withImage,
        ...this.$route.query,
      });
      this.items = this.items.concat(response.data);
      this.offset = response.offset + response.limit;

      if (response.count < response.limit || response.data.length === 0) {
        this.fetchLocked = true;
      }
    },
    reset() {
      this.items = [];
      this.limit = 30;
      this.offset = 0;
      this.fetchLocked = false;
    },
    async resetAndFetchItems() {
      this.reset();
      await this.fetchItems();
    },
  },
  computed: {
    searchText() {
      return this.$route.params.searchText;
    },
  },
  watch: {
    bottom(bottom) {
      if (this.bottomWatcherOn && bottom) {
        this.fetchItems();
      }
    },
    async $route() {
      this.bottomWatcherOn = false;
      await this.resetAndFetchItems();
      this.bottomWatcherOn = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>