import { create } from 'apisauce';
import config from '../../config/config-server'
import HTTPService from '@/services/http';

export const baseURL = `${config.BACKEND_HOST}`;


const api = create({
    baseURL,
    crossDomain: true,
    responseType: 'json',
    headers: {
        'content-type': 'application/json',
    },
})


const apiArrayBuffer = create({
    baseURL,
    crossDomain: true,
    responseType: 'arraybuffer',
})


const apiMultipartFormData = create({
    baseURL,
    crossDomain: true,
    responseType: 'json',
    headers: {
        'accept': 'application/json',
        'content-type': 'multipart/form-data',
    },
})


const apiBlob = create({
    baseURL,
    crossDomain: true,
    responseType: 'blob',
})


const JSONHTTPService = new HTTPService(api)
const ArrayBufferHTTPService = new HTTPService(apiArrayBuffer)
const FormHTTPService = new HTTPService(apiMultipartFormData)
const BlobHTTPService = new HTTPService(apiBlob)


export const getTags = async function () {
    const response = await JSONHTTPService.get('/tags/', { authRequired: false });
    return response.data;
}

export const createTag = async function (data) {
    const response = await JSONHTTPService.post('/tags/', { data, authRequired: true });
    return response.data;
}

export const deleteTag = async function (tagId) {
    const response = await JSONHTTPService.delete(`/tags/${tagId}`, { authRequired: true });
    return response.data;
}

export const getItems = async function (params) {
    const response = await JSONHTTPService.get('/items/', { params, authRequired: false });
    return { limit: response.data.limit, offset: response.data.offset, count: response.data.count, data: response.data.data };
}

export const getItemsDetailed = async function (params) {
    const response = await JSONHTTPService.get('/items/detailed', { params, authRequired: true });
    return response.data;
}

export const getItem = async function (itemId) {
    const response = await JSONHTTPService.get(`/items/${itemId}`, { authRequired: false });
    return response.data;
}

export const getItemImagesInfo = async function (itemId) {
    const response = await JSONHTTPService.get(`/items/${itemId}/images`, { authRequired: false });
    return response.data;
}

export const getItemDefaultImage = async function (itemId) {
    const response = await ArrayBufferHTTPService.get(`/items/${itemId}/default-image`, { authRequired: false });
    return { data: response.data, status: response.status, contentType: response.contentType };
}

export const getOccasion = async function (occasionId) {
    const response = await JSONHTTPService.get(`/occasions/${occasionId}`, { authRequired: false });
    return response.data;
}

export const getOccasions = async function () {
    const response = await JSONHTTPService.get('/occasions/', { authRequired: false });
    return response.data;
}

export const getOccasionsCyclical = async function () {
    const response = await JSONHTTPService.get('/occasions/cyclical', { authRequired: false });
    return response.data;
}

export const getOccasionTypes = async function () {
    const response = await JSONHTTPService.get('/occasion-types/', { authRequired: false });
    return response.data;
}

export const createOccasionType = async function (data) {
    const response = await JSONHTTPService.post('/occasion-types/', { data, authRequired: true });
    return response.data;
}

export const deleteOccasionType = async function (occasionTypeId) {
    const response = await JSONHTTPService.delete(`/occasion-types/${occasionTypeId}`, { authRequired: true });
    return response.data;
}

export const getPttkSections = async function () {
    const response = await JSONHTTPService.get('/pttk-sections/', { authRequired: false });
    return response.data;
}

export const getVoivodeshipList = async function () {
    const response = await JSONHTTPService.get('/voivodeships/', { authRequired: false });
    return response.data;
}

export const getOrganizers = async function () {
    const response = await JSONHTTPService.get('/organizers/', { authRequired: false });
    return response.data;
}

export const createOrganizer = async function (data) {
    const response = await JSONHTTPService.post('/organizers/', { data, authRequired: true });
    return response.data;
}

export const deleteOrganizer = async function (organizerId) {
    const response = await JSONHTTPService.delete(`/organizers/${organizerId}`, { authRequired: true });
    return response.data;
}

export const createOccasion = async function (data) {
    const response = await JSONHTTPService.post('/occasions/', { data, authRequired: true });
    return response.data;
}

export const updateOccasion = async function (occasionId, data) {
    const response = await JSONHTTPService.put(`/occasions/${occasionId}`, { data, authRequired: true });
    return response.data;
}

export const deleteOccasion = async function (occasionId) {
    const response = await JSONHTTPService.delete(`/occasions/${occasionId}`, { authRequired: true });
    return response.data;
}

export const createOccasionCyclical = async function (data) {
    const response = await JSONHTTPService.post('/occasions/cyclical', { data, authRequired: true });
    return response.data;
}

export const getItemImage = async function (imageId) {
    const response = await ArrayBufferHTTPService.get(`/images/${imageId}`, { authRequired: false });
    return { data: response.data, contentType: response.contentType };
}

export const getItemTypes = async function () {
    const response = await JSONHTTPService.get('/item-types/', { authRequired: false });
    return response.data;
}

export const createItemType = async function (data) {
    const response = await JSONHTTPService.post('/item-types/', { data, authRequired: true });
    return response.data;
}

export const deleteItemType = async function (itemTypeId) {
    const response = await JSONHTTPService.delete(`/item-types/${itemTypeId}`, { authRequired: true });
    return response.data;
}

export const getMaterialTypes = async function () {
    const response = await JSONHTTPService.get('/material-types/', { authRequired: false });
    return response.data;
}

export const createMaterialType = async function (data) {
    const response = await JSONHTTPService.post('/material-types/', { data, authRequired: true });
    return response.data;
}

export const deleteMaterialType = async function (materialTypeId) {
    const response = await JSONHTTPService.delete(`/material-types/${materialTypeId}`, { authRequired: true });
    return response.data;
}

export const createItem = async function (data) {
    const response = await JSONHTTPService.post('/items/', { data, authRequired: true });
    return response.data;
}

export const updateItem = async function (itemId, data) {
    const response = await JSONHTTPService.put(`/items/${itemId}`, { data, authRequired: true });
    return response.data;
}

export const assignImageToItem = async function (imageId, itemId) {
    const response = await FormHTTPService.post(`/images/${imageId}/item/${itemId}`, { authRequired: true });
    return response.data;
}

export const createImage = async function (imageId, form) {
    const response = await JSONHTTPService.post(`/images/${imageId}`, { data: form, authRequired: true });
    return response.data;
}

export const patchImage = async function (imageId, data) {
    const response = await JSONHTTPService.patch(`/images/${imageId}`, { data, authRequired: true });
    return response.data;
}

export const removeItemImages = async function (itemId) {
    const response = await JSONHTTPService.delete(`/items/${itemId}/images`, { authRequired: true });
    return response.data;
}

export const getSummary = async function () {
    const response = await JSONHTTPService.get('/summary/', { authRequired: false });
    return response.data;
}

export const exportXlsx = async function () {
    const response = await BlobHTTPService.get('/export/xlsx', { authRequired: true });
    return { data: response.data, fileName: response.headers["x-suggested-filename"] };
    // return { data: response.data, fileName: response.headers["content-disposition"] };
}