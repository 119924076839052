<template>
  <v-row justify="center">
    <v-col cols="12" sm="10" md="8" lg="8">
      <v-card ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <TextFieldForm
                label="Sygnatura*"
                :valueDefault="item.signature"
                :disabled="true"
                :counter="8"
              ></TextFieldForm>
            </v-col>
            <v-col>
              <TextFieldForm
                label="Nazwa*"
                :valueDefault="item.name"
                :rules="[rules.requiredField, rules.maxLength(255)]"
                @valueChanged="setValue('name', $event)"
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <TextFieldForm
                label="Typ*"
                :valueDefault="item.item_type.name"
                :disabled="true"
              ></TextFieldForm>
            </v-col>
            <v-col cols="3">
              <TextFieldAutocompleteForm
                label="Materiał"
                :valueDefault="item.material_type"
                :items="materialTypes"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('materialType', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="3">
              <TextFieldAutocompleteForm
                label="Rok imprezy"
                :valueDefault="item.occasion_year"
                :items="years"
                @valueChanged="setValue('occasionYear', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="3">
              <TextFieldAutocompleteForm
                label="Rok pozyskania"
                :valueDefault="item.obtaining_year"
                :items="years"
                @valueChanged="setValue('obtainingYear', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextFieldForm
                label="Lokalizacja"
                :valueDefault="item.location"
                :rules="[rules.maxLength(128)]"
                @valueChanged="setValue('location', $event)"
                :counter="128"
              ></TextFieldForm>
            </v-col>
            <v-col cols="4">
              <TextFieldAutocompleteForm
                label="Cykl"
                :valueDefault="item.occasion"
                :items="occasions"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('occasion', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="2">
              <v-dialog v-model="dialogNewOccasion" max-width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dodaj
                  </v-btn>
                  <v-spacer></v-spacer>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ addOccasionFormTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="newOccasionName"
                            label="Nazwa*"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeAddOccasion">
                      Anuluj
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="submitAddOccasion"
                    >
                      Zapisz
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextFieldAutocompleteForm
                label="Dziedzina"
                :valueDefault="item.occasion_types"
                :multiple="true"
                :items="occasionTypesList"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('occasionTypes', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="4">
              <TextFieldAutocompleteForm
                label="Organizatorzy"
                :valueDefault="item.organizers"
                :multiple="true"
                :items="organizersList"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('organizers', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="2">
              <v-dialog v-model="dialogNewOrganizer" max-width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dodaj
                  </v-btn>
                  <v-spacer></v-spacer>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ addOrganizerFormTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="newOrganizerName"
                            label="Nazwa*"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeAddOrganizer">
                      Anuluj
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="submitAddOrganizer"
                    >
                      Zapisz
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextFieldForm
                label="Autor"
                :valueDefault="item.author"
                :rules="[rules.maxLength(128)]"
                @valueChanged="setValue('author', $event)"
                :counter="128"
              ></TextFieldForm>
            </v-col>
            <v-col cols="6">
              <TextFieldForm
                label="Darczyńca"
                :valueDefault="item.donor"
                :rules="[rules.maxLength(128)]"
                @valueChanged="setValue('donor', $event)"
                :counter="128"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <TextFieldForm
                label="Treść"
                :valueDefault="item.content"
                :rules="[rules.maxLength(256)]"
                @valueChanged="setValue('content', $event)"
                outlined
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <TextFieldForm
                label="Dodatkowe informacje (publiczne)"
                :valueDefault="item.additional_info"
                :rules="[rules.maxLength(256)]"
                @valueChanged="setValue('additionalInfo', $event)"
                outlined
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <TextFieldForm
                label="Uwagi"
                :valueDefault="item.comments"
                :rules="[rules.maxLength(256)]"
                @valueChanged="setValue('comments', $event)"
                outlined
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-file-input
            v-model="images"
            accept="image/png, image/jpeg, image/jpg"
            placeholder="Zdjęcia"
            multiple
            show-size
            counter
            prepend-icon="mdi-image-plus"
            @change="setImagesChanged"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-file-input
            v-model="files"
            placeholder="Inne pliki"
            multiple
            show-size
            counter
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn text @click="close"> Cofnij </v-btn>
          <v-spacer></v-spacer>
          <v-slide-x-reverse-transition>
            <v-tooltip v-if="formHasErrors" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="my-0"
                  v-bind="attrs"
                  @click="resetForm"
                  v-on="on"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Odśwież</span>
            </v-tooltip>
          </v-slide-x-reverse-transition>
          <v-btn :disabled="!formIsValid" color="primary" text @click="submit">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { nanoid } from "nanoid";
import {
  getItem,
  getItemTypes,
  getItemImage,
  getItemImagesInfo,
  getMaterialTypes,
  getOccasions,
  createOccasion,
  createOrganizer,
  getOccasionTypes,
  getOrganizers,
  createImage,
  updateItem,
  assignImageToItem,
  patchImage,
  removeItemImages
} from "@/shared/api";
// import { getImage } from "@/shared/utils/image";
import { getYearsRange } from "@/shared/utils/dates";
import TextFieldForm from "@/components/forms/TextFieldForm.vue";
import TextFieldAutocompleteForm from "@/components/forms/TextFieldAutocompleteForm.vue";

export default {
  name: "EditItem",
  components: { TextFieldForm, TextFieldAutocompleteForm },
  data() {
    return {
      dialogNewOccasion: false,
      dialogNewOrganizer: false,
      addOccasionFormTitle: "Nowy cykl",
      addOrganizerFormTitle: "Nowy organizator",
      imagesChanged: false,
      signature: null,
      name: null,
      author: null,
      donor: null,
      occasion: null,
      obrainingYear: null,
      itemType: null,
      materialType: null,
      description: null,
      itemTypes: null,
      materialTypes: null,
      occasionTypesList: null,
      occasionTypes: null,
      organizersList: null,
      organizers: null,
      images: [],
      files: [],
      imagesInfo: null,
      item: null,
      newOccasionName: null,
      newOrganizerName: null,
      formHasErrors: false,
      errorMessages: "",
      rules: {
        requiredField: (val) => !!val || "Pole jest wymagane",
        maxLength(maxNum) {
          return (val) =>
            val.length <= maxNum ||
            `Pole nie może zawierać więcej niż ${maxNum} znaków`;
        },
      },
    };
  },
  // watch: {
  //   name() {
  //     this.errorMessages = "";
  //   },
  // },
  async created() {
    this.itemTypes = await getItemTypes();
    this.materialTypes = await getMaterialTypes();
    this.occasionTypesList = await getOccasionTypes();
    this.organizersList = await getOrganizers();
    this.occasions = await getOccasions();
    this.item = await getItem(this.itemId);
    this.imagesInfo = await getItemImagesInfo(this.itemId);
    await this.fillImagesInput();
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    years() {
      return getYearsRange(1950);
    },
    formIsValid() {
      return this.name;
    },
  },
  methods: {
    setValue(property, value) {
      this[property] = value;
    },
    setImagesChanged() {
      this.imagesChanged = true;
    },
    async fillImagesInput() {
      this.imagesInfo.map(async (info) => {
        const { data, contentType } = await getItemImage(info.id);
        console.log(contentType);
        const bytes = new Uint8Array(data);
        this.images.push(new File(bytes, info.name));
      });
    },
    async saveImage(file, itemId, isDefaultImage) {
      var form = new FormData();
      form.append("file", file);

      const imageId = nanoid(16);
      await createImage(imageId, form);

      await assignImageToItem(imageId, itemId);

      // await addImageToItem(itemId, form);

      if (isDefaultImage) {
        await patchImage(imageId, { is_default: true });
      }
    },
    // async saveImage(file, itemId, isDefaultImage) {
    //   var form = new FormData();
    //   form.append("file", file);

    //   let imageInfo = await assignImageToItem(itemId, form);

    //   if (isDefaultImage) {
    //     await patchImage(imageInfo.id, { is_default: true });
    //   }
    // },
    async submit() {
      const payload = {
        name: this.name,
        material_id:
          typeof this.materialType === "object" && this.materialType !== null
            ? this.materialType.id
            : this.materialType,
        occasion_year: this.occasionYear,
        obtaining_year: this.obtainingYear,
        location: this.location === "" ? null : this.location,
        occasion_id:
          typeof this.occasion === "object" && this.occasion !== null
            ? this.occasion.id
            : this.occasion,
        author: this.author === "" ? null : this.author,
        donor: this.donor === "" ? null : this.donor,
        organizers_ids:
          typeof this.organizers === "object" && this.organizers !== null
            ? this.organizers.map((o) =>
                typeof o === "object" && o !== null ? o.id : o
              )
            : null,
        occasion_types_ids:
          typeof this.occasionTypes === "object" && this.occasionTypes !== null
            ? this.occasionTypes.map((ot) =>
                typeof ot === "object" && ot !== null ? ot.id : ot
              )
            : null,
        content: this.content === "" ? null : this.content,
        additional_info:
          this.additionalInfo === "" ? null : this.additionalInfo,
        comments: this.comments === "" ? null : this.comments,
      };

      await updateItem(this.itemId, payload);

      if (this.imagesChanged === true) {
        await removeItemImages(this.itemId);

        await this.saveImage(this.images[0], this.itemId, true);

        if (this.images.length > 1) {
          this.images
            .slice(1)
            .map(async (file) => await this.saveImage(file, this.itemId, false));
        }
      }

      this.$router.back();

      // this.formHasErrors = false;

      // Object.keys(this.form).forEach((f) => {
      //   if (!this.form[f]) this.formHasErrors = true;

      //   this.$refs[f].validate(true);
      // });
    },
    closeAddOccasion() {
      this.dialogNewOccasion = false;
      this.$nextTick(() => {
        this.newOccasionName = null;
      });
    },
    closeAddOrganizer() {
      this.dialogNewOrganizer = false;
      this.$nextTick(() => {
        this.newOrganizerName = null;
      });
    },
    async submitAddOccasion() {
      const newOccasionId = nanoid(16);
      const payload = {
        id: newOccasionId,
        name: this.newOccasionName,
      };

      await createOccasion(payload);
      // this.itemTypes = await getItemTypes();
      this.closeAddOccasion();
      this.occasions = await getOccasions();
    },
    async submitAddOrganizer() {
      const newOrganizerId = nanoid(16);
      const payload = {
        id: newOrganizerId,
        name: this.newOrganizerName,
      };

      await createOrganizer(payload);
      this.closeAddOrganizer();
      this.organizersList = await getOrganizers();
    },
    close() {
      this.$router.back();
    },
  },
  watch: {
    dialogNewOccasion(val) {
      val || this.closeAddOccasion();
    },
    dialogNewOrganizer(val) {
      val || this.closeAddOrganizer();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>