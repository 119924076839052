import { create } from 'apisauce';
import { StatusCodes } from 'http-status-codes';
import config from '../../config/config-server'
import AuthService from '@/services/auth';
// import authHeader from '@/services/auth-header.js';
import LocalStorage from '@/shared/utils/localStorage.js'

const baseURL = `${config.AUTH_HOST}`;

const api = create({
    baseURL,
    crossDomain: true,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        // ...authHeader(),
    },
})


async function sendRequest(method, url, { params = {} } = {}) {
    api.setHeader('Authorization', 'Bearer ' + LocalStorage.get('accessToken'))
    var response = null;
    const accessToken = LocalStorage.get('accessToken');

    if (accessToken === null) {
        const authorization = await AuthService.refresh();
        if (!authorization) {
            return { data: null, status: false };
        }
    }

    response = await api[method](url, params);
    if (response.status !== StatusCodes.UNAUTHORIZED) {
        return { data: response.data, status: response.status };
    }

    const authorization = await AuthService.refresh();
    if (!authorization) {
        return { data: null, status: false };
    }
    response = await api[method](url, params);
    return { data: response.data, status: response.status };
}


class UsersService {
    async fetchUsers() {
        const response = await sendRequest('get', '/users');
        if (response.status !== StatusCodes.OK) {
            return
        }
        return response.data;
    }

    async create(payload) {
        const response = await sendRequest('post', '/users', { params: payload });
        if (response.status !== StatusCodes.OK) {
            return
        }
    }

    async update(userId, payload) {
        const response = await sendRequest('patch', `/users/${userId}`, { params: payload });
        if (response.status !== StatusCodes.OK) {
            return
        }
    }

    async delete(userId) {
        const response = await sendRequest('delete', `/users/${userId}`);
        if (response.status !== StatusCodes.OK) {
            return
        }
    }
}

export default new UsersService();
