<template>
  <v-container fluid fill-height @keydown.enter="logInUserAndProceed">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Logowanie</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                prepend-icon="mdi-account"
                name="login"
                label="Login"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="password"
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Hasło"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="logInUserAndProceed">Zaloguj</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="loginFailed"
      :timeout="timeout"
      color="red"
      absolute
      bottom
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="black"
          v-bind="attrs"
          @click="loginFailed = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  props: {
    nextPath: {
      type: String,
      required: false,
      default: "/gallery",
    },
  },
  data() {
    return {
      username: null,
      password: null,
      loginFailed: false,
      text: "Błędny login lub hasło. Spróbuj ponownie.",
      timeout: 3000,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async logInUserAndProceed() {
      const response = await this.login({ username: this.username, password: this.password });
      if (response !== null) {
        this.routeNext();
        return;
      }
      this.loginFailed = true;
    },
    routeNext() {
      this.$emit("rerenderHeaderBar");
      this.$router.replace({ path: this.nextPath });
    },
  },
};
</script>

<style></style>