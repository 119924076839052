import LocalStorage from '@/shared/utils/localStorage.js'

export default function authHeader() {
    const accessToken = LocalStorage.get('accessToken');

    if (accessToken) {
        return { Authorization: 'Bearer ' + accessToken };
    } else {
        return {};
    }
}
