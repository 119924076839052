<template>
  <div class="fixed">
    <v-card
      class="ma-4 text-md-body-1 text-lg-h6 text-xl-h5 overflow-x-visible flex-shrink-1 flex-grow-1"
      color="grey lighten-4"
    >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(0)"
          >Wszystkie eksponaty: {{ summary.total }}</v-btn
        ></v-row
      >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(1)">
          Znaczki: {{ summary.znaczek }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(2)">
          Plakietki: {{ summary.plakietka }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(3)">
          Pieczęci: {{ summary.pieczec }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(4)">
          Proporczyki: {{ summary.proporczyk }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(5)">
          Odznaki: {{ summary.odznaka }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(7)">
          Plakaty: {{ summary.plakat }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn class="ma-1" plain dense @click="routeTo(8)"
        >Medale: {{ summary.medal }}
        </v-btn></v-row
      >
      <v-checkbox
        class="fixed"
        v-model="withImageCheckbox"
        label="Pokaż tylko ze zdjęciem"
      ></v-checkbox>
    </v-card>
  </div>
</template>

<script>
import { getSummary } from "@/shared/api";
import LocalStorage from '@/shared/utils/localStorage.js'

export default {
  name: "Summary",
  data() {
    return {
      summary: {},
      withImageCheckbox: LocalStorage.get('withImage') === null ? true : LocalStorage.get('withImage'),
    };
  },
  async created() {
    this.summary = await getSummary();
  },
  methods: {
    routeTo(itemTypeId) {
      if (itemTypeId > 0) {
        this.$router.push({
          path: `/gallery`,
          query: { item_type_id: itemTypeId },
        });
      } else {
        this.$router.push("/gallery");
      }
    },
  },
  watch: {
    withImageCheckbox(newValue) {
      LocalStorage.set('withImage', newValue);
      this.$emit("withImageCheckboxChanged");
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  top: 30;
}
</style>