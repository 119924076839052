class LocalStorage {
    get(key, defaultValue = null) {
        const item = JSON.parse(localStorage.getItem(key));

        if (item === undefined || item === null) {
            return defaultValue;
        }
        if (item.ttl === null) {
            return item.value;
        }
        if (item.ttl - Date.now() > 0) {
            return item.value;
        }
        localStorage.removeItem(key);
        return defaultValue;
    }

    set(key, value, ttl = null) {
        const item = JSON.stringify({ value: value, ttl: ttl });
        localStorage.setItem(key, item);
    }

    remove(key) {
        localStorage.removeItem(key);
    }
}


export default new LocalStorage();