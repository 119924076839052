import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=2b6cd418&scoped=true&"
import script from "./NavBar.vue?vue&type=script&lang=js&"
export * from "./NavBar.vue?vue&type=script&lang=js&"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=2b6cd418&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b6cd418",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VNavigationDrawer})
