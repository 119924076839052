import { getItemImage } from "@/shared/api";

export const getImage = async function (imageId) {
    const { data, contentType } = await getItemImage(imageId);
    // const bytes = new Uint8Array(data);
    // const base64data = bytes.reduce(
    //     (d, byte) => (d += String.fromCharCode(byte)),
    //     ""
    // );
    // // TODO: ogarnac to btoa na nowa wersje
    // return `data:${contentType};base64,${btoa(base64data)}`;
    return `data:${contentType};base64,${Buffer.from(
        data,
        "binary"
    ).toString("base64")}`;
}
