<template>
    <v-card class="mr-5">
      <v-card-title>
          {{ tableTitle }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        loading-text="Pobieranie danych ... Proszę czekać"
        :footer-props="{
            itemsPerPageText: 'Wiersze na stronie',
            pageText: '$vuetify.dataFooter.pageText',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Wyszukaj"
              single-line
              hide-details
            ></v-text-field>
            <!-- <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col v-if="editForm" cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedUser.username"
                          filled
                          readonly
                          label="Login"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedUser.last_name"
                          :filled="editForm"
                          :readonly="editForm"
                          label="Nazwisko"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedUser.email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog> -->
          </v-toolbar>
        </template>

        <!-- <template v-slot:item.role="{ item }">
          <v-chip color="gray" dense>
            {{ item.role.name }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon>
        </template> -->
        <!-- <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template> -->
      </v-data-table>
    </v-card>
</template>

<script>
export default {
  name: "SimpleDataTable",
  components: {},
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tableTitle: {
        type: String,
        required: true,
    }
  },
  data() {
    return {
      search: "",
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>