import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.js'
// import itemTypes from './modules/itemTypes.js'
import users from './modules/users.js'

Vue.use(Vuex)


export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    users
  }
})
