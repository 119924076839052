<template>
  <v-row justify="center">
    <v-col cols="12" sm="10" md="8" lg="8">
      <v-card ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <TextFieldForm
                label="Sygnatura*"
                :rules="[rules.requiredField, rules.maxLength(8)]"
                @valueChanged="setValue('signature', $event)"
                placeholder="XX-YYYY"
                :counter="8"
              ></TextFieldForm>
            </v-col>
            <v-col>
              <TextFieldForm
                label="Nazwa*"
                :rules="[rules.requiredField, rules.maxLength(256)]"
                @valueChanged="setValue('name', $event)"
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <TextFieldAutocompleteForm
                label="Typ*"
                :items="itemTypes"
                :rules="[rules.requiredField]"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('itemType', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="3">
              <TextFieldAutocompleteForm
                label="Materiał"
                :items="materialTypes"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('materialType', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="3">
              <TextFieldAutocompleteForm
                label="Rok imprezy"
                :items="years"
                @valueChanged="setValue('occasionYear', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="3">
              <TextFieldAutocompleteForm
                label="Rok pozyskania"
                :items="years"
                @valueChanged="setValue('obtainingYear', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextFieldForm
                label="Lokalizacja"
                :rules="[rules.maxLength(128)]"
                @valueChanged="setValue('location', $event)"
                :counter="128"
              ></TextFieldForm>
            </v-col>
            <v-col cols="4">
              <TextFieldAutocompleteForm
                label="Cykl"
                :items="occasions"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('occasion', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="2">
              <v-dialog v-model="dialogNewOccasion" max-width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dodaj
                  </v-btn>
                  <v-spacer></v-spacer>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ addOccasionFormTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="newOccasionName"
                            label="Nazwa*"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeAddOccasion">
                      Anuluj
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="submitAddOccasion"
                    >
                      Zapisz
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextFieldAutocompleteForm
                label="Dziedzina"
                :multiple="true"
                :items="occasionTypesList"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('occasionTypes', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="4">
              <TextFieldAutocompleteForm
                label="Organizatorzy"
                :multiple="true"
                :items="organizersList"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('organizers', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="2">
              <v-dialog v-model="dialogNewOrganizer" max-width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dodaj
                  </v-btn>
                  <v-spacer></v-spacer>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ addOrganizerFormTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="newOrganizerName"
                            label="Nazwa*"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeAddOrganizer">
                      Anuluj
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="submitAddOrganizer"
                    >
                      Zapisz
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextFieldForm
                label="Autor"
                :rules="[rules.maxLength(128)]"
                @valueChanged="setValue('author', $event)"
                :counter="128"
              ></TextFieldForm>
            </v-col>
            <v-col cols="6">
              <TextFieldForm
                label="Darczyńca"
                :rules="[rules.maxLength(128)]"
                @valueChanged="setValue('donor', $event)"
                :counter="128"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="11">
              <TextFieldAutocompleteForm
                label="Impreza*"
                :items="occasions"
                itemText="name"
                itemValue="id"
                @valueChanged="setValue('occasion', $event)"
              ></TextFieldAutocompleteForm>
            </v-col>
            <v-col cols="1">
              <v-dialogNewOccasion v-model="dialogNewOccasion" max-width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dodaj
                  </v-btn>
                  <v-spacer></v-spacer>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ addOccasionFormTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="4">
                          <v-checkbox
                            v-model="isCyclicalOccasionCheckbox"
                            label="Impreza cykliczna"
                          ></v-checkbox>
                        </v-col>
                        <v-col v-if="isCyclicalOccasionCheckbox" cols="9">
                          <TextFieldAutocompleteForm
                            label="Impreza*"
                            :items="occasionsCyclical"
                            itemText="name"
                            itemValue="id"
                            @valueChanged="
                              setValue('newOccasionOccasionCyclical', $event)
                            "
                          ></TextFieldAutocompleteForm>
                          <v-col cols="2">
                            <v-dialogNewOccasion v-model="subdialogNewOccasion" max-width="50%">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Dodaj
                                </v-btn>
                                <v-spacer></v-spacer>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="headline">{{
                                    subFormTitle
                                  }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="9">
                                        <v-text-field
                                          v-model="newOccasionCyclical.name"
                                          label="Nazwa"
                                        ></v-text-field>
                                      </v-col> </v-row></v-container
                                ></v-card-text>
                                <v-card-actions>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeAddOccasionCyclical"
                                  >
                                    Anuluj
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="submitAddOccasionCyclical"
                                  >
                                    Zapisz
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialogNewOccasion>
                          </v-col>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            v-model="newOccasion.name"
                            label="Nazwa*"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="9">
                          <TextFieldAutocompleteForm
                            label="Rodzaj*"
                            :items="occasionTypesList"
                            :multiple="true"
                            itemText="name"
                            itemValue="id"
                            @valueChanged="setValue('newOccasionTypes', $event)"
                          ></TextFieldAutocompleteForm>
                        </v-col>

                        <v-col cols="9">
                          <TextFieldAutocompleteForm
                            label="Rok*"
                            :items="years"
                            @valueChanged="setValue('newOccasionYear', $event)"
                          ></TextFieldAutocompleteForm>
                        </v-col>
                        <v-col cols="9">
                          <TextFieldAutocompleteForm
                            label="Oddział PTTK*"
                            :items="pttkSections"
                            itemText="name"
                            itemValue="id"
                            @valueChanged="
                              setValue('newOccasionPttkSection', $event)
                            "
                          ></TextFieldAutocompleteForm>
                        </v-col>
                        <v-col cols="9">
                          <TextFieldAutocompleteForm
                            label="Województwo*"
                            :items="voivodeshipList"
                            itemText="name"
                            itemValue="id"
                            @valueChanged="
                              setValue('newOccasionVoivodeship', $event)
                            "
                          ></TextFieldAutocompleteForm>
                        </v-col>
                        <v-col cols="9">
                          <TextFieldAutocompleteForm
                            label="Organizatorzy"
                            :items="organizersList"
                            itemText="name"
                            itemValue="id"
                            multiple="true"
                            @valueChanged="
                              setValue('newOccasionorganizersList', $event)
                            "
                          ></TextFieldAutocompleteForm>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            v-model="newOccasion.localization"
                            label="Miejsce"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeAddOccasion">
                      Anuluj
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="submitAddOccasion"
                    >
                      Zapisz
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialogNewOccasion>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <TextFieldForm
                label="Treść"
                :rules="[rules.maxLength(256)]"
                @valueChanged="setValue('content', $event)"
                outlined
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <TextFieldForm
                label="Dodatkowe informacje (publiczne)"
                :rules="[rules.maxLength(256)]"
                @valueChanged="setValue('additionalInfo', $event)"
                outlined
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <TextFieldForm
                label="Uwagi"
                :rules="[rules.maxLength(256)]"
                @valueChanged="setValue('comments', $event)"
                outlined
                :counter="256"
              ></TextFieldForm>
            </v-col>
          </v-row>
          <v-file-input
            v-model="images"
            accept="image/png, image/jpeg, image/jpg"
            placeholder="Zdjęcia"
            multiple
            show-size
            counter
            prepend-icon="mdi-image-plus"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-file-input
            v-model="files"
            placeholder="Inne pliki"
            multiple
            show-size
            counter
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn text @click="close"> Cofnij </v-btn>
          <v-spacer></v-spacer>
          <v-slide-x-reverse-transition>
            <v-tooltip v-if="formHasErrors" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="my-0"
                  v-bind="attrs"
                  @click="resetForm"
                  v-on="on"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Odśwież</span>
            </v-tooltip>
          </v-slide-x-reverse-transition>
          <v-btn :disabled="!formIsValid" color="primary" text @click="submit">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { nanoid } from "nanoid";
import {
  getItemTypes,
  getMaterialTypes,
  getOccasions,
  // getOccasionsCyclical,
  getOccasionTypes,
  // getPttkSections,
  // getVoivodeshipList,
  getOrganizers,
  createItem,
  createImage,
  createOccasion,
  createOrganizer,
  // createOccasionCyclical,
  patchImage,
  assignImageToItem,
} from "@/shared/api";
import { getYearsRange } from "@/shared/utils/dates";
// import TextAreaForm from "@/components/forms/TextAreaForm.vue";
import TextFieldForm from "@/components/forms/TextFieldForm.vue";
import TextFieldAutocompleteForm from "@/components/forms/TextFieldAutocompleteForm.vue";

export default {
  name: "NewItem",
  components: { TextFieldForm, TextFieldAutocompleteForm },
  data() {
    return {
      dialogNewOccasion: false,
      dialogNewOrganizer: false,
      // subdialogNewOccasion: false,
      addOccasionFormTitle: "Nowy cykl",
      addOrganizerFormTitle: "Nowy organizator",
      signature: null,
      name: null,
      author: null,
      donor: null,
      obrainingYear: null,
      itemType: null,
      materialType: null,
      occasionYear: null,
      content: null,
      additionalInfo: null,
      comments: null,
      itemTypes: null,
      materialTypes: null,
      occasions: null,
      // occasionsCyclical: null,
      occasionTypesList: null,
      occasionTypes: null,
      occasion: undefined,
      // isCyclicalOccasionCheckbox: false,
      // newOccasionCyclical: {
      //   name: null,
      // },
      newOccasionName: null,
      newOrganizerName: null,
      // newOccasionOccasionCyclical: null,
      // newOccasionPttkSection: null,
      // newOccasionVoivodeship: null,
      // newOccasionorganizersList: null,
      // pttkSections: null,
      // voivodeshipList: null,
      organizersList: null,
      organizers: null,
      images: null,
      files: null,
      formHasErrors: false,
      errorMessages: "",
      rules: {
        requiredField: (val) => !!val || "Pole jest wymagane",
        maxLength(maxNum) {
          return (val) =>
            val.length <= maxNum ||
            `Pole nie może zawierać więcej niż ${maxNum} znaków`;
        },
      },
    };
  },
  // watch: {
  //   name() {
  //     this.errorMessages = "";
  //   },
  // },
  async created() {
    this.itemTypes = await getItemTypes();
    this.materialTypes = await getMaterialTypes();
    this.occasions = await getOccasions();
    // this.occasionsCyclical = await getOccasionsCyclical();
    this.occasionTypesList = await getOccasionTypes();
    // this.pttkSections = await getPttkSections();
    // this.voivodeshipList = await getVoivodeshipList();
    this.organizersList = await getOrganizers();
  },
  computed: {
    years() {
      return getYearsRange(1950);
    },
    // occasion_cyclical() {
    //   if (this.occasion === undefined || this.occasion === null) {
    //     return undefined;
    //   }

    //   const occasionLocal = this.occasions.find(
    //     (occ) => occ.id === this.occasion
    //   );
    //   console.log(occasionLocal);
    //   return occasionLocal.occasion_cyclical;
    // },
    formIsValid() {
      return this.signature && this.name && this.itemType;
    },
  },
  methods: {
    setValue(property, value) {
      this[property] = value;
    },
    async saveImage(file, itemId, isDefaultImage) {
      var form = new FormData();
      form.append("file", file);

      const imageId = nanoid(16);
      await createImage(imageId, form);

      await assignImageToItem(imageId, itemId);

      // await addImageToItem(itemId, form);

      if (isDefaultImage) {
        await patchImage(imageId, { is_default: true });
      }
    },
    closeAddOccasion() {
      this.dialogNewOccasion = false;
      this.$nextTick(() => {
        this.newOccasionName = null;
      });
    },
    closeAddOrganizer() {
      this.dialogNewOrganizer = false;
      this.$nextTick(() => {
        this.newOrganizerName = null;
      });
    },
    // closeAddOccasionCyclical() {
    //   this.subdialogNewOccasion = false;
    // },
    async submitAddOccasion() {
      const newOccasionId = nanoid(16);
      const payload = {
        id: newOccasionId,
        name: this.newOccasionName,
      };

      await createOccasion(payload);
      this.closeAddOccasion();
      this.occasions = await getOccasions();
    },
    async submitAddOrganizer() {
      const newOrganizerId = nanoid(16);
      const payload = {
        id: newOrganizerId,
        name: this.newOrganizerName,
      };

      await createOrganizer(payload);
      this.closeAddOrganizer();
      this.organizersList = await getOrganizers();
    },
    // async submitAddOccasionCyclical() {
    //   const newOccasionId = nanoid(16);
    //   const payload = {
    //     id: newOccasionId,
    //     name: this.newOccasionCyclical.name,
    //   };

    //   await createOccasionCyclical(payload);
    //   this.closeAddOccasionCyclical();
    //   this.occasionsCyclical = await getOccasionsCyclical();
    // },
    async submit() {
      const newItemId = nanoid(16);
      const payload = {
        id: newItemId,
        signature: this.signature,
        name: this.name,
        item_type_id: this.itemType,
        material_id: this.materialType,
        occasion_id: this.occasion,
        occasion_year: this.occasionYear,
        location: this.location,
        content: this.content,
        additional_info: this.additionalInfo,
        obtaining_year: this.obtainingYear,
        donor: this.donor,
        author: this.author,
        comments: this.comments,
        organizers_ids: this.organizers,
        occasion_types_ids: this.occasionTypes,
      };

      await createItem(payload);

      this.images.sort((a, b) => a.name.localeCompare(b.name));
      await this.saveImage(this.images[0], newItemId, true);
      if (this.images.length > 1) {
        this.images
          .slice(1)
          .map(async (file) => await this.saveImage(file, newItemId, false));
      }

      this.$router.push("/gallery");

      // this.formHasErrors = false;

      // Object.keys(this.form).forEach((f) => {
      //   if (!this.form[f]) this.formHasErrors = true;

      //   this.$refs[f].validate(true);
      // });
    },
    close() {
      this.$router.back();
    },
  },
  watch: {
    dialogNewOccasion(val) {
      val || this.closeAddOccasion();
    },
    dialogNewOrganizer(val) {
      val || this.closeAddOrganizer();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>