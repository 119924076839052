<template>
  <div>
    <v-navigation-drawer
      v-model="drawerLocal"
      app
      dark
      expand-on-hover
      color="secondary"
    >
      <v-list-item> </v-list-item>

      <!-- <v-divider></v-divider> -->

      <v-list dense nav>
        <NavBarLink title="Strona główna" routeTo="/" icon="mdi-home" />

        <!-- <v-divider></v-divider> -->

        <!-- <NavBarLink
          title="Aktualności"
          routeTo="/news"
          icon="mdi-newspaper-variant"
        /> -->
        <!-- <NavBarLink
          title="O projekcie"
          routeTo="/about"
          icon="mdi-information"
        /> -->
        <!-- <NavBarLink title="Historia" routeTo="/history" icon="mdi-chess-rook" /> -->
        <!-- <NavBarLink title="Regulamin" routeTo="/regulations" icon="mdi-file" /> -->
        <NavBarLink title="Kontakt" routeTo="/contact" icon="mdi-email" />
        <div v-if="userLoggedIn" @click="logoutUser">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="" class="white--text"> Wyloguj się </router-link>
            </v-list-item-title>
          </v-list-item>
        </div>
        <NavBarLink
          v-else
          title="Zaloguj się"
          routeTo="/login"
          icon="mdi-account-box"
        />
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NavBarLink from "@/components/NavBarLink";

export default {
  name: "NavBar",
  components: { NavBarLink },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawerLocal: { ...this.drawer },
    };
  },
  methods: {
    ...mapActions(["logout"]),
    logoutUser() {
      this.logout();
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["userLoggedIn"]),
  },
  watch: {
    drawer(newValue) {
      this.drawerLocal = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
// .xxx {
//   color: #2e363f;
// }
</style>