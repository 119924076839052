import { StatusCodes } from 'http-status-codes';
import config from '../../config/config-server'

import AuthService from '@/services/auth';
import authHeader from '@/services/auth-header.js';
import LocalStorage from '@/shared/utils/localStorage.js'

export const baseURL = `${config.BACKEND_HOST}`;


class HTTPService {
    constructor(api) {
        this.api = api;
    }

    async get(url, { params = {}, authRequired = false }) {
        const response = await this.sendRequest('get', url, { params, authRequired });
        if (response.status !== StatusCodes.OK) {
            console.error(response)
        }
        return response;
    }

    async post(url, { data = {}, authRequired = true }) {
        const response = await this.sendRequest('post', url, { data, authRequired });
        if (response.status !== StatusCodes.CREATED) {
            console.error(response)
        }
        return response;
    }

    async put(url, { data = {}, authRequired = true }) {
        const response = await this.sendRequest('put', url, { data, authRequired });
        if (response.status !== StatusCodes.OK) {
            console.error(response)
        }
        return response;
    }

    async patch(url, { data = {}, authRequired = true }) {
        const response = await this.sendRequest('patch', url, { data, authRequired });
        if (response.status !== StatusCodes.OK) {
            console.error(response)
        }
        return response;
    }

    async delete(url, { authRequired = true }) {
        const response = await this.sendRequest('delete', url, { authRequired });
        if (response.status !== StatusCodes.OK) {
            console.error(response)
        }
        return response;
    }

    async sendRequest(method, url, { params = {}, data = {}, authRequired = true }) {
        var response = null;
        const accessToken = LocalStorage.get('accessToken');

        if (authRequired && accessToken === null) {
            const authorization = await AuthService.refresh();
            if (!authorization) {
                return { data: null, status: false, contentType: response.headers["content-type"], headers: response.headers };
            }
        }

        response = await this.api[method](url, data, { params, headers: authRequired ? authHeader() : {} });
        if (response.status !== StatusCodes.UNAUTHORIZED) {
            return { data: response.data, status: response.status, contentType: response.headers["content-type"], headers: response.headers };
        }

        const authorization = await AuthService.refresh();
        if (!authorization) {
            return { data: null, status: false };
        }
        response = await this.api[method](url, data, { params, headers: authRequired ? authHeader() : {} });
        return { data: response.data, status: response.status, contentType: response.headers["content-type"], headers: response.headers };
    }

}


export default HTTPService;