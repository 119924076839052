import AuthService from '@/services/auth';
import LocalStorage from '@/shared/utils/localStorage.js'

const user = LocalStorage.get('user');

const initialState = { user: user, loggedIn: user === null ? false : true };


export default {
    state: initialState,
    getters: {
        userLoggedIn: state => state.loggedIn,
        userInfo: state => {
            return state.user ? {
                ...user,
                fullName: state.user.firstName + " " + state.user.lastName,
                initials: state.user.firstName.charAt(0).toUpperCase() + state.user.lastName.charAt(0).toUpperCase()
            } : null
        }
    },
    actions: {
        async login({ commit }, { username, password }) {
            const response = await AuthService.login(username, password)
            if (response === null) {
                commit('loginFailure');
                return null;
                    // return Promise.reject(error);
            } else {
                commit('loginSuccess', user);
            }

            // await AuthService.login(username, password).then(
            //     user => {
            //         commit('loginSuccess', user);
            //         // return Promise.resolve(token);

            //     },
            //     error => {
            //         commit('loginFailure');
            //         return Promise.reject(error);
            //     }
            // )
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.loggedIn = false;
            state.user = null;
        },
    }
};