<template>
  <v-autocomplete
    v-model="value"
    :label="label"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="multiple"
    :outlined="outlined"
    :dense="dense"
    color="green darken-2"
    clearable
  >
  </v-autocomplete>
</template>

<script>
export default {
  name: "TextFieldAutocompleteForm",
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: null,
      required: true,
    },
    itemText: {
      type: String,
      required: false,
    },
    itemValue: {
      type: String,
      required: false,
    },
    valueDefault: {
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    if (this.valueDefault) {
      this.value = this.valueDefault;
    }
  },
  watch: {
    value(newValue) {
      this.$emit("valueChanged", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>