import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#12564a',
                secondary: '#2E363F',
                accent: '#9c27b0',
                error: '#f44336',
                warning: '#ff9800',
                info: '#00bcd4',
                success: '#4caf50'
                //     primary: '#009688',
                //     secondary: '#00bcd4',
                //     accent: '#673ab7',
                //     error: '#b71c1c',
                //     warning: '#ff9800',
                //     info: '#03a9f4',
                //     success: '#4caf50'
                // },
                // {

                // primary: '#8bc34a',
                // secondary: '#009688',
                // accent: '#9c27b0',
                // error: '#f44336',
                // warning: '#ff9800',
                // info: '#00bcd4',
                // success: '#4caf50'
            },
            dark: {
                primary: '#546E7A'
            }
        },
    },
});
