import UsersService from '@/services/users';

export default {
    state: { users: null },
    getters: {
        users: state => state.users
    },
    actions: {
        async fetchUsers({ commit }) {
            await UsersService.fetchUsers().then(
                users => {
                    commit('fetchUsers', users);

                },
            )
        },
        async deleteUser({ commit }, userId) {
            await UsersService.delete(userId);
            commit('deleteUser', userId);
        },
    },
    mutations: {
        fetchUsers(state, users) {
            state.users = users;
        },
        deleteUser(state, userId) {
            state.users.splice(state.users.findIndex((user) => user.id == userId), 1);
        }
    }
};