<template>
  <div>
    <v-hover v-slot="{ hover }" open-delay="100" close-delay="100">
      <router-link :to="itemDetailsLink" target="_blank" rel="nofollow">
        <v-card class="mx-auto" max-width="400">
          <v-img
            v-if="imageDefault"
            :src="imageDefault"
            aspect-ratio="1"
            contain
            class="grey lighten-2"
          >
            <v-expand-transition>
              <div
                v-if="hover"
                class="
                  d-flex
                  flex-column
                  align-center
                  transition-fast-in-fast-out
                  white
                  darken-2
                  v-card--reveal
                  black--text
                "
                style="height: 100%"
              >
                <p class="px-4 body-1 font-weight-bold">
                  {{ itemObj.signature }}
                </p>
                <p class="px-4 body-2 font-weight-bold">
                  {{ itemObj.name }}
                </p>
              </div>
            </v-expand-transition>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-img
            v-else
            :src="require('@/assets/no_image.png')"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <v-expand-transition>
              <div
                v-if="hover"
                class="
                  d-flex
                  flex-column
                  align-center
                  transition-fast-in-fast-out
                  white
                  darken-2
                  v-card--reveal
                  black--text
                "
                style="height: 100%"
              >
                <p class="px-4 body-1 font-weight-bold">
                  {{ itemObj.signature }}
                </p>
                <p class="px-4 body-2 font-weight-bold">
                  {{ itemObj.name }}
                </p>
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </router-link>
    </v-hover>
  </div>
</template>

<script>
import { getImage } from "@/shared/utils/image";

export default {
  name: "ImageHoverable",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemObj: { ...this.item },
      imageDefault: undefined,
    };
  },
  async created() {
    if (this.itemObj.image_id === null) {
      return;
    }
    // const { data, status, contentType } = await getImage(this.itemObj.image_id);
    this.imageDefault = await getImage(this.itemObj.image_id);
    // if (status == 404) {
    //   return;
    // }
    // this.imageDefault = `data:${contentType};base64,${Buffer.from(
    //   data,
    //   "binary"
    // ).toString("base64")}`;
    // }
  },
  computed: {
    itemDetailsLink() {
      return "/item/" + this.itemObj.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  // align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.xxx {
  display: block;
}
</style>
