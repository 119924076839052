var authHost;
var backendHost;

switch (process.env.NODE_ENV) {
    case 'develop':
        authHost = 'http://localhost:7000';
        backendHost = 'http://localhost:8000';
        break;
    default:
        authHost = 'https://auth.gpt.org.pl';
        backendHost = 'https://api.gpt.org.pl'
        break;
}

module.exports = {
    AUTH_HOST: authHost,
    BACKEND_HOST: backendHost,
}