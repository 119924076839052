<template>
  <v-container>
    <v-card>
      <v-card-title>
        Lista Użytkowników
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
        loading-text="Pobieranie użytkowników... Proszę czekać"
        multi-sort
        :footer-props="{
            itemsPerPageText: 'Wiersze na stronie',
            pageText: '$vuetify.dataFooter.pageText',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Wyszukaj"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Dodaj Użytkownika
                </v-btn>
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col v-if="editForm" cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedUser.username"
                          filled
                          readonly
                          label="Login"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedUser.first_name"
                          :filled="editForm"
                          :readonly="editForm"
                          label="Imię"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedUser.last_name"
                          :filled="editForm"
                          :readonly="editForm"
                          label="Nazwisko"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedUser.email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="8">
                        <v-autocomplete
                          v-model="editedUser.role"
                          label="Rola*"
                          :items="roles"
                          :valueDefault="editedUser.role"
                          itemText="name"
                          itemValue="id"
                          @valueChanged="
                            setObjectValue('editedUser', 'role', $event)
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Anuluj
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="createOrUpdateUser">
                    Zapisz
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6"
                  >Czy na pewno chcesz usunąć konto użytkownika
                  {{ editedUser.username }}?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Anuluj</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteUserConfirm"
                    >Usuń</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
          </v-toolbar>
        </template>

        <template v-slot:item.role="{ item }">
          <v-chip color="gray" dense>
            {{ item.role.name }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon>
          <!-- <v-icon small @click="deleteUser(item)"> mdi-delete </v-icon> -->
        </template>
        <!-- <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
// import { nanoid } from "nanoid";
import UsersService from "@/services/users";

export default {
  name: "Users",
  components: {},
  data() {
    return {
      users: [],
      roles: [
        { id: 1, name: "ADMIN" },
        { id: 2, name: "REGULAR" },
      ],
      dialog: false,
      // dialogDelete: false,
      search: "",
      loading: false,
      headers: [
        {
          text: "LOGIN",
          align: "start",
          value: "username",
        },
        { text: "IMIĘ", value: "first_name" },
        { text: "NAZWISKO", value: "last_name" },
        { text: "EMAIL", value: "email" },
        { text: "ROLA", value: "role" },
        { text: "AKCJE", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedUser: {
        id: null,
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        role: null,
      },
      // defaultItem: {
      //   name: null,
      //   year: null,
      //   voivodeship: null,
      //   pttk_section: null,
      //   occasion_cyclical: null,
      //   occasion_types: null,
      //   occasion_organizers: null,
      //   localization: null,
      // },
    };
  },
  async created() {
    this.loading = true;
    this.users = await UsersService.fetchUsers();
    this.loading = false;
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nowy użytkownik" : "Edycja użytkownika";
    },
    editForm() {
      return this.editedIndex !== -1;
    },
  },
  methods: {
    setObjectValue(object, property, value) {
      this[object][property] = value;
    },
    editUser(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.dialog = true;
    },
    // deleteUser(item) {
    //   this.editedIndex = this.users.indexOf(item);
    //   this.editedUser = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },
    // async deleteUserConfirm() {
    //   await UsersService.delete(this.editedUser.id);
    //   // this.users.splice(this.editedIndex, 1);
    //   this.closeDelete();
    //   this.users = await UsersService.fetchUsers();
    // },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // closeDelete() {
    //   this.dialogDelete = false;
    //   this.$nextTick(() => {
    //     this.editedUser = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },
    async createOrUpdateUser() {
      // if (this.editedIndex > -1) {
      await this.updateUser();
      // } else {
      //   await this.createUser();
      // }
    },
    // async createUser() {
    //   const payload = this.prepareCreatePayload();
    //   await UsersService.create(payload);

    //   this.close();
    //   this.users = await UsersService.fetchUsers();
    // },
    async updateUser() {
      const payload = this.prepareUpdatePayload();
      await UsersService.update(this.editedUser.id, payload);

      this.close();
      this.users = await UsersService.fetchUsers();
    },
    // prepareCreatePayload() {
    //   var payload = {
    //     id: nanoid(16),
    //     password: "test123", // TODO: ogarnac to jakos
    //     first_name: this.editedUser.first_name,
    //     last_name: this.editedUser.last_name,
    //     email: this.editedUser.email,
    //     role_id:
    //       typeof this.editedUser.role === "object"
    //         ? this.editedUser.role.id
    //         : this.editedUser.role,
    //   };
    //   return payload;
    // },
    prepareUpdatePayload() {
      var payload = {
        email: this.editedUser.email,
        role_id:
          typeof this.editedUser.role === "object"
            ? this.editedUser.role.id
            : this.editedUser.role,
      };
      return payload;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>