<template>
  <div>
    <v-container>     
      <v-row>
        <v-container class="d-flex">
        <v-col cols="2" xs="2" sm="2" md="4" lg="4"></v-col>
        <v-col cols="2" xs="2" sm="2" md="1" lg="1" class="d-flex-row align-self-center">
        <a href="https://warszawski.pttk.pl/">
          <v-img
            :src="require('@/assets/pttk_warszawa_logo_transparent.png')"
            contain
          ></v-img>
        </a>
        </v-col>
        <v-col cols="4" sm="4" md="2" lg="2" class="d-flex-row align-self-center">
        <a href="http://datajana.pl/">
          <v-img
            :src="require('@/assets/datajana_logo.png')"
            contain
          ></v-img>
        </a>
        </v-col>
        <v-col cols="2" sm="2" md="1" lg="1" class="d-flex-row align-self-center">
        <a href="http://pttk.pl/">
          <v-img
            :src="require('@/assets/pttk_logo_transparent.png')"
            contain
          ></v-img>
        </a>
        </v-col>
        <v-col cols="2" sm="3" md="4" lg="4"></v-col>
        </v-container>
      </v-row>
    <p class="text-center font-weight-bold text-h5 text-md-h6 text-lg-h5 text-xl-h4">Galeria Pamiątek Turystycznych Klubu Turystycznego DATAJANA</p>
    <p class="text-center font-weight-medium text-subtitle-2 text-md-subtitle-2 text-lg-title">przy Oddziale Warszawskim Polskiego Towarzystwa Turystyczno-Krajoznawczego</p>

    <p class="indent text-justify font-weight-regular text-subtitle-1 text-md-subtitle-2 text-lg-subtitle-1 text-xl-h6">
      Kolekcjonerstwo w&nbsp;Polskim Towarzystwie Turystyczno-Krajoznawczym jest jednym
      z&nbsp;elementów ruchu społecznego, mającym na celu poznawanie kraju ojczystego - jego
      historii, zabytków i&nbsp;przyrody. Gromadzenie różnorodnych pamiątek turystycznych, ich
      popularyzowanie i&nbsp;udostępnianie przyczynia się do wzbogacania wiedzy o&nbsp;Polsce.
    </p>

    <p class="text-justify font-weight-regular text-subtitle-1 text-md-subtitle-2 text-lg-subtitle-1 text-xl-h6">
      Kolekcjonerstwo pamiątek turystycznych w&nbsp;Klubie DATAJANA funkcjonuje od 1982 roku.
    </p>

    <p class="indent text-justify font-weight-regular text-subtitle-1 text-md-subtitle-2 text-lg-subtitle-1 text-xl-h6">
      Na początku były to znaczki, proporczyki, plakietki, odciski pieczęci, śpiewniki, regulaminy
      imprez, w&nbsp;których uczestniczyli członkowie klubu. Z&nbsp;czasem do galerii zaczęły napływać
      dary od innych zaprzyjaźnionych osób. Były to odznaki regionalne, medale, dyplomy
      uczestnictwa, czasopisma turystyczne, widokówki, mapy, regulaminy imprez i&nbsp;książki.
      Lata 1983-1986 to pierwszy okres aktywnej działalności i&nbsp;otwarcia na zewnątrz. Udział
      w&nbsp;spotkaniach Kolekcjonerów Krajoznawców na warszawskiej Starówce zaowocował
      zdobyciem kolejno IV, III i II miejsca. Rok 2010 zainicjował prace mające na celu
      uporządkowanie i&nbsp;skatalogowanie zbiorów. Od 2016 roku systematycznie organizowane są
      wystawy pamiątek turystycznych w&nbsp;trakcie trwania Mazowieckich Sejmików Krajoznawstwa
      (Konstancin-Jeziorna, Wołomin, Serock) oraz w&nbsp;Zarządzie Głównym PTTK. W&nbsp;2020 roku
      wystawa pamiątek uświetniła obchody 100-lecia Bitwy Warszawskiej w&nbsp; Ossowie.
      Artykuły o&nbsp;zbiorach Galerii ukazywały się w&nbsp;czasopismach o&nbsp;zasięgu krajowym, 
      a&nbsp;opiekunowie Galerii opowiadali o&nbsp;gromadzonych pamiątkach turystycznych w&nbsp;audycji
      radiowej.
    </p>

    <p class="indent text-justify font-weight-regular text-subtitle-1 text-md-subtitle-2 text-lg-subtitle-1 text-xl-h6">
      W&nbsp;dowód uznania za gromadzone zbiory i&nbsp;ich udostępnianie oraz popularyzację
      krajoznawstwa Klub DATAJANA otrzymał wiele wyróżnień. Najważniejsza z&nbsp;nich to Srebrna
      Odznaka Kolekcjonerska.
    </p>

    <p class="text-justify font-weight-regular text-subtitle-1 text-md-subtitle-2 text-lg-subtitle-1 text-xl-h6">
      Obecnie zbiory liczą około 10 tysięcy eksponatów. Największą część stanowią odznaki
      turystyczno-krajoznawcze.
    </p>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "Gallery",
};
</script>

<style lang="scss" scoped>

.logos {
  align-items: center;
}

.indent {
  text-indent: 30px;
}

</style>

