<template>
  <div>
    <v-list-item link>
      <v-list-item-icon>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <router-link :to="routeTo" class="white--text">
          {{ title }}
        </router-link>
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "NavBarLink",
  props: {
    title: {
      type: String,
      required: true,
    },
    routeTo: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>