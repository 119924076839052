<template>
  <v-app>
    <HeaderBar/>
    <v-main>
      <router-view @rerenderHeaderBar="reload"/>
    </v-main>
  </v-app>
</template>

<script>
import HeaderBar from "@/components/HeaderBar";

export default {
  name: "App",
  components: { HeaderBar },
  methods: {
    reload() {
      this.$forceUpdate();
    }
  }
};
</script>


<style lang="scss">
#app {
  font-family: sans-serif;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  //   text-align: center;
  //   color: #2c3e50;
  background-color: #e1e2e4;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
