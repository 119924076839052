<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="id"
    :search="search"
    :expanded.sync="expanded"
    single-expand
    show-expand
    dense
    multi-sort
    class="elevation-1"
    no-data-text="Brak eksponatów"
    :footer-props="{
        itemsPerPageText: 'Wiersze na stronie',
        pageText: '$vuetify.dataFooter.pageText',
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Wyszukaj"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.occasion_types="{ item }">
      <div
        v-for="occasionType in item.occasion_types"
        :key="occasionType.id"
        color="gray"
        dense
      >
        {{ occasionType.name }}
      </div>
    </template>
    <template v-slot:item.organizers="{ item }">
      <div
        v-for="organizer in item.organizers"
        :key="organizer.id"
        color="gray"
        dense
      >
        {{ organizer.name }}
      </div>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-data-table
          :headers="headersExpanded"
          :items="[item]"
          hide-default-footer
          disable-pagination
          disable-filtering
          disable-sort
        >
          <template v-slot:item.actions="{ item }">
            <router-link :to="itemEditLink(item.id)">
              <v-btn small color="error">Edytuj</v-btn>
            </router-link>
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { getItemsDetailed } from "@/shared/api";

export default {
  name: "ItemsDataTable",
  props: {
    itemTypeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      expanded: [],
      headers: [
        { text: "SYGNATURA", value: "signature", width: "12%" },
        { text: "NAZWA", value: "name", width: "20%" },
        { text: "CYKL", value: "occasion.name", width: "15%" },
        { text: "DZIEDZINA", value: "occasion_types", width: "12%" },
        { text: "LOKALIZACJA", value: "location", width: "12%" },
        { text: "ROK", value: "occasion_year", width: "7%" },
        { text: "ORGANIZATORZY", value: "organizers", width: "15%" },
        { text: '', value: 'data-table-expand', width: "1%"},
      ],
      headersExpanded: [
        { text: "MATERIAŁ", value: "material_type.name", width: "8%" },
        { text: "ROK POZYSKANIA", value: "obtaining_year", width: "5%" },
        { text: "TREŚĆ", value: "content", width: "20%" },
        { text: "DODATKOWE INFORMACJE", value: "additional_info", width: "20%" },
        { text: "AUTOR", value: "author", width: "10%" },
        { text: "DARCZYŃCA", value: "donor", width: "10%" },
        { text: "UWAGI", value: "comments", width: "10%" },
        { text: "", value: "actions", sortable: false, width: "5%" },
      ],
      offset: 0,
      limit: 999999,
      items: [],
    };
  },
  async created() {
    // this.items = [{signature: 'ME-0001', name: "fdsfsdfsd"}];//await this.fetchItems();
    this.items = await this.fetchItems();
  },
  methods: {
    async fetchItems() {
      const response = await getItemsDetailed({
        item_type_id: this.itemTypeId,
      });
      return response;
    },
    itemEditLink(itemId) {
      return "/editItem/" + itemId;
    },
  }
};
</script>

<style lang="scss" scoped>
</style>