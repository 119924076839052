<template>
  <v-container>
    <v-btn
      class="mb-2"
      color="primary"
      @click="exportToXlsxFile"
    >
      Eksport do Excela
    </v-btn>
    <v-card class="mx-auto">

      <v-tabs v-model="tab" background-color="primary" dark fixed-tabs>
        <v-tab
          v-for="itemType in itemTypes"
          :key="itemType.id"
        >{{ tabNamesMapping[itemType.code] }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="itemType in itemTypes"
          :key="itemType.id"
        >
          <v-card>
            <ItemsDataTable :itemTypeId="itemType.id"></ItemsDataTable>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import ItemsDataTable from "@/components/ItemsDataTable.vue";
// import { getItemTypes } from "@/shared/api";
import { getItemTypes, exportXlsx } from "@/shared/api";
import { saveAs } from "file-saver";

export default {
  name: "ItemsList",
  components: { ItemsDataTable },
  data() {
    return {
      tab: null,
      itemTypes: null,
      tabNamesMapping: {
        ME: 'Medale',
        OP: 'Pieczęci',
        OZ: 'Odznaczenia',
        OD: 'Odznaki',
        PK: 'Plakaty',
        PL: 'Plakietki',
        PR: 'Proporczyki',
        ZN: 'Znaczki',
      }
    };
  },
  async created() {
    this.itemTypes = await getItemTypes();
  },
  methods: {
    async exportToXlsxFile() {
      const response = await exportXlsx();
      console.log(response);
      saveAs(response.data, response.fileName);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>