<template>
  <v-container class="d-flex">
    <SimpleDataTable :headers="headers" :items="occasions" :tableTitle="occasionsTableTitle"></SimpleDataTable>
    <SimpleDataTable :headers="headers" :items="organizers" :tableTitle="organizersTableTitle"></SimpleDataTable>
  </v-container>
</template>

<script>
import { getOrganizers, getOccasions } from "@/shared/api";
import SimpleDataTable from "@/components/SimpleDataTable.vue";

export default {
  name: "OtherData",
  components: { SimpleDataTable },
  data() {
    return {
      headers: [
        {
          text: "NAZWA",
          align: "start",
          value: "name",
        },
      ],
      occasions: [],
      organizers: [],
      occasionsTableTitle: "Lista imprez cyklicznych",
      organizersTableTitle: "Lista organizatorów",
    };
  },
  async created() {
    this.occasions = await getOccasions();
    this.organizers = await getOrganizers();
  }
};
</script>

<style lang="scss" scoped>
</style>