var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"expanded":_vm.expanded,"single-expand":"","show-expand":"","dense":"","multi-sort":"","no-data-text":"Brak eksponatów","footer-props":{
      itemsPerPageText: 'Wiersze na stronie',
      pageText: '$vuetify.dataFooter.pageText',
  }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.occasion_types",fn:function(ref){
  var item = ref.item;
return _vm._l((item.occasion_types),function(occasionType){return _c('div',{key:occasionType.id,attrs:{"color":"gray","dense":""}},[_vm._v(" "+_vm._s(occasionType.name)+" ")])})}},{key:"item.organizers",fn:function(ref){
  var item = ref.item;
return _vm._l((item.organizers),function(organizer){return _c('div',{key:organizer.id,attrs:{"color":"gray","dense":""}},[_vm._v(" "+_vm._s(organizer.name)+" ")])})}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.headersExpanded,"items":[item],"hide-default-footer":"","disable-pagination":"","disable-filtering":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.itemEditLink(item.id)}},[_c('v-btn',{attrs:{"small":"","color":"error"}},[_vm._v("Edytuj")])],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }